import { $get, $post, $put } from './http'
import enterprise from './module/enterprise'
import project from './module/project'
import user from './module/user'
import app from './module/app'
import invite from './module/invite'
import moment from './module/moment'
import share from './module/share'
import config from '@/config'

console.log(process.env)
const api = {
  getConfig: (data = {}) => {
    return $get(`/config/config.json?v=${new Date().getTime()}`, data)
  },
  // 账号密码登录
  login: (data) => {
    return $post(config.BASE_URL + '/core/auth/password-login', data)
  },
  // 手机验证码登录
  loginByPhone: (data) => {
    return $post(config.BASE_URL + '/core/auth/sms-login', data)
  },
  // 注册获取验证码
  getCodeWhenSignin: (data) => {
    return $get(config.BASE_URL + '/core/user/register/sms-code', data)
  },
  // 登录获取验证码
  getCodeWhenLogin: (data) => {
    return $get(config.BASE_URL + '/core/auth/sms-code', data)
  },

  // 获取重置密码验证码
  // 原接口废弃：/core/v1.0/sms/reset-password
  getVerifyCode: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/reset-password', data)
  },
  // 重置密码（短信）
  resetPassword: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/user/reset-password/sms', data)
  },

  // 获取绑定手机验证码。
  // 原接口废弃：/core/v1.0/user/bind/phone
  getCodeWhenBindPhone: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/bind-phone', data)
  },
  // 绑定手机
  bindPhone: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/user/bind/phone', data)
  },

  // 获取绑定邮箱验证码
  getCodeWhenBindEmail: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/email/bind-email', data)
  },
  // 通过邮箱验证码绑定邮箱
  bindEmail: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/user/bind/email', data)
  },
  enterprise,
  user,
  app,
  project,
  invite,
  moment,
  share
}

export default api
