import { $delete, $get, $post } from '../http'
import config from '@/config'
export default {
  // 获取企业应用列表
  getEnterpriseApps: (data) => {
    return $get(config.BASE_URL + '/core/org/app/list', data)
  },
  // 获取项目应用列表
  getProjectApps: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/project/user/appAssignment', data)
  },
  // // 获取项目应用列表
  // getProjectApps: (data) => {
  //   return $get(config.BASE_URL + '/core/project/app/list', data)
  // },
  // 获取收藏的应用
  getfavApps: (data) => {
    return $get(config.BASE_URL + '/core/org/app/fav/list', data)
  },
  // 收藏应用
  favApp: (data) => {
    return $post(`${config.BASE_URL}/core/org/app/fav`, data)
  },
  // 删除收藏的应用
  deleteFavApp: (data) => {
    return $delete(`${config.BASE_URL}/core/org/app/fav`, data)
  }
}
