import { CLIENT } from '../type'
import api from '@/services/api'

const state = {
  enterpriseSelected: null,
  enterpriseList: [],
  enterpriseInfo: null
}

const getters = {
}

const mutations = {
  [CLIENT.ENTERPRISE.SET_SELECTED] (state, enterpriseSelected) {
    state.enterpriseSelected = enterpriseSelected
    // localStorage.setItem('enterpriseSelected', enterpriseSelected)
  },
  [CLIENT.ENTERPRISE.SET_LIST] (state, enterpriseList) {
    state.enterpriseList = enterpriseList
  },
  [CLIENT.ENTERPRISE.SET_INFO] (state, enterpriseInfo) {
    state.enterpriseInfo = enterpriseInfo
  }
}

const actions = {
  [CLIENT.ENTERPRISE.SET_SELECTED] ({ commit, state }, enterpriseSelected) {
    api.enterprise.choice({}, { orgId: enterpriseSelected }).then(res => {
      if (res.code === 200) {
        commit(CLIENT.ENTERPRISE.SET_SELECTED, enterpriseSelected)
        // if (state.enterpriseList && state.enterpriseList.length) {
        //   state.enterpriseList.map(item => {
        //     if (item.orgId === enterpriseSelected) commit(CLIENT.ENTERPRISE.SET_INFO, item)
        //   })
        //   console.log(state.enterpriseInfo)
        // }
      }
    })
  },
  [CLIENT.ENTERPRISE.SET_LIST] ({ commit, dispatch, state }) {
    api.enterprise.getList().then(res => {
      if (res.code === 200) {
        const result = res.data
        if (result && result.length) {
          commit(CLIENT.ENTERPRISE.SET_LIST, result)
          // const localStr = localStorage.getItem('enterpriseSelected')
          // let localId = null
          // localId = localStr ? Number(localStr) : result[0].orgId

          // const localId = result[0].orgId
          // dispatch(CLIENT.ENTERPRISE.SET_SELECTED, localId)
          console.log(state.enterpriseList)
        }
      }
    })
  },
  [CLIENT.ENTERPRISE.SET_INFO] ({ commit, state }, param) {
    // debugger
    api.enterprise.getDetail(param).then(res => {
      if (res.code === 200) {
        // debugger
        commit(CLIENT.ENTERPRISE.SET_INFO, res.data)
      }
    })
  }
}

export default {
  namespaced: true, // 为了解决不同模块命名冲突的问题，将不同模块的namespaced:true
  state,
  getters,
  actions,
  mutations
}
