import { $get, $put } from '../http'
import config from '@/config'
export default {
  // 获取个人信息
  getInfo: (data) => {
    return $get(config.BASE_URL + '/core/user/info', data)
  },
  // 更新个人密码
  updateUserPass: (data) => {
    return $put(config.BASE_URL + '/core/user/pwd', data)
  },
  // 更新个人信息
  updateUserInfo: (data) => {
    return $put(config.BASE_URL + '/core/user/info', data)
  },

  // 获取个人信息 V2
  getInfoV2: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/user/me', data)
  }
}
