import { $post, $get } from '../http'
import config from '@/config'
export default {
  // 邀请项目人员
  inviteProjectMember: ({ orgId, projectId } = {}, data = {}) => {
    return $post(config.BASE_URL + '/core/org/project/invite' + `?orgId=${orgId}&projectId=${projectId}`, data)
  },
  // 邀请企业人员
  inviteOrgMember: ({ orgId, projectId } = {}, data = {}) => {
    return $post(config.BASE_URL + '/core/org/invite' + `?orgId=${orgId}&projectId=${projectId}`, data)
  },
  // 邀请注册
  inviteRegister: (data, isPhone) => {
    return $post(config.BASE_URL + `/core/user/inviteRegister${isPhone ? '?type=sms' : ''}`, data)
  },
  // 验证邀请码
  checkInviteId: (data) => {
    return $get(config.BASE_URL + '/core/common/invite', data)
  }
}
