import { $get, $put } from '../http'
import config from '@/config'
export default {
  // 获取企业列表
  getList: (data) => {
    return $get(config.BASE_URL + '/core/org/user/bind/list', data)
  },
  // 选择企业
  choice: (data, params) => {
    return $put(config.BASE_URL + '/core/org/user/choice', data, params)
  },
  // 获取企业详情
  getDetail: (data, params) => {
    return $get(config.BASE_URL + '/core/org/detail', data, params)
  },
  // 获取工作台数据
  getWorkstationData: (data) => {
    return $get(config.EYEGLE_EYE + '/annotation/workstation', data)
  },
  // 获取企业角色列表
  getRoleList: (data) => {
    return $get(config.BASE_URL + '/core/org/role/list', data)
  },
  // 获取企业人员列表
  getMemberList: (data) => {
    return $get(config.BASE_URL + '/core/org/user/list', data)
  }
}
