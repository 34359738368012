import { $delete, $get, $post } from '../http'
import config from '@/config'
export default {
  // 获取个人信息
  getUserInfo: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/user', data)
  },

  // 获取表单列表
  getMomentList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/moment/list', data)
  },

  // 获取表单详情
  getFormDetail: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/form', data)
  },

  // 获取统计数据
  getChartData: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/hashtag/statistics', data)
  },

  // 获取标签列表
  getTagList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/hashtag/list', data)
  },

  // 保存标签
  addTag: (data) => {
    return $post(config.PHOTO_APP_PATH + '/hashtag', data)
  },

  // 添加或恢复评论
  comment: (data) => {
    return $post(config.PHOTO_APP_PATH + '/moment/comment', data)
  },

  // 获取评论列表
  getCommentList: (data) => {
    return $get(config.PHOTO_APP_PATH + '/moment/comment/list', data)
  },

  // 获取OSS上传链接
  getOssUrl: (data) => {
    return $get(config.PHOTO_APP_PATH + '/oss/common/auth', data)
  },

  // 获取水印相机OSS上传链接
  getWatermarkOssUrl: (data) => {
    return $get(config.PHOTO_APP_PATH + '/oss/watermark/auth', data)
  },

  // 上传表单
  uploadForm: (data) => {
    return $post(config.PHOTO_APP_PATH + '/form', data)
  },

  // 查询消息通知列表
  getMessageList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/notify/list', data)
  },

  // 查询消息通知列表
  getMessageNum: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/notify/qty', data)
  },

  // 点赞动态
  likeMoment: (data = {}) => {
    return $post(config.PHOTO_APP_PATH + '/moment/like', data)
  },

  // 获取项目列表
  getProjectList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/project/list', data)
  },

  // 发布动态
  publishMoment: (data = {}) => {
    return $post(config.PHOTO_APP_PATH + '/moment', data)
  },

  // 查询动态详情
  getMomentDetail: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/moment', data)
  },

  // 删除动态
  deleteMoment: (data = {}) => {
    return $delete(config.PHOTO_APP_PATH + '/moment', data)
  }
}
