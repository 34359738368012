import { $get, $post, $delete } from '../http'
import config from '@/config'
export default {
  // 获取项目列表
  getProjectList: (data = {}) => {
    return $get(config.BASE_URL + '/core/org/project/list', data)
  },
  // 获取项目成员列表
  getProjectMemberList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/user/list', data)
  },
  // 同步成员到Jepass
  postUsersToJP: (data = {}) => {
    return $post(config.EYEGLE_EYE + '/eeJpForm/importJpUser', data)
  },
  // 获取项目角色关系
  getProjectUserRoleList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/role/user/relation/list', data)
  },
  //  绑定用户的项目角色
  bindProjectUserRole: (data = {}) => {
    return $post(config.BASE_URL + '/core/project/role/bind/user', data)
  },
  //  绑定用户的项目角色
  unBindProjectUserRole: (data = {}, param = {}) => {
    return $delete(config.BASE_URL + '/core/project/role/unbind/user', data, param)
  },
  // 获取项目角色列表
  getProjectRoleList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/role/list', data)
  },
  //  移除项目人员
  removeProjectRole: (data = {}, param = {}) => {
    return $delete(config.BASE_URL + '/core/project/user', data, param)
  }
}
