
import { Modal, Menu, MenuItem } from 'ant-design-vue'
import { h } from 'vue'
import store from '@/store'
import { CLIENT } from '@/store/type'
import api from '@/services/api'
import { client } from '@/router/path'
import i18n from '@/locale/i18n'

let selectedKey
let modal
let options
let router

export const showModal = async (mainRouter) => {
  router = mainRouter
  // await store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_LIST)
  const res = await api.enterprise.getList()
  options = []
  modal = null
  if (res.code === 200) {
    if (res.data && res.data?.length) {
      res.data.map(i => {
        options.push(<MenuItem key={i.orgId}>{i.orgName}</MenuItem>)
      })
    }
    selectedKey = res.data[0]?.orgId
    if ((options && options.length === 1)) {
      // const router = useRouter()
      store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_SELECTED, selectedKey)
      // console.log(router)
      router.push({
        name: client.PROJECT_LIST,
        params: {
          orgId: selectedKey
        }
      })
      return
    }
    console.log(selectedKey)
    console.log(options)
    console.log(res)
    modal = Modal.info({
      title: i18n.global.t('invite.selectOrg'),
      content: h(<div>
        <Menu onSelect={select} selectedKeys={[selectedKey]}>
          {options}
        </Menu>
      </div>),
      onOk: submit,
      okText: i18n.global.t('invite.sure')
    })
  }
}

const select = (id) => {
  selectedKey = id.key
  modal.update({
    content: h(<div>
      <Menu onSelect={select} selectedKeys={[selectedKey]}>
        {options}
      </Menu>
    </div>)
  })
  console.log(selectedKey)
}

const submit = () => {
  if (selectedKey) {
    // store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_SELECTED, selectedKey)
    router.push({
      name: client.PROJECT_LIST,
      params: {
        orgId: selectedKey
      }
    })
  }
}
