export default {
  header: {
    settings: '設置',
    notify: '通知',
    language: '語言',
    workbench: '工作台',
    projectList: '項目列表',
    projectCollection: '項目收藏',
    enterprise: '企業',
    logout: '退出登錄',
    message: '消息',
    projectSetting: '項目設置 ',
    userCenter: '個人中心'
  },
  enterprise: {
    enterpriseSettings: '企業設置',
    projectSettings: '項目設置',
    menu: {
      basicInformation: '基礎信息',
      enterpriseInformation: '企業信息',
      user: '人員',
      organizationAndMembers: '組織與成員',
      enterpriseRoleManagement: '企業角色管理',
      projectRoleManagement: '項目角色管理',
      application: '應用',
      projectApplicationManagement: '項目應用管理',
      project: '項目',
      projectManagement: '項目管理',
      projectCapacity: '項目容量',
      personnelQuota: '人員名額',
      memberManagement: '人員管理'
    },
    memberManagement: {
      title: '人員管理',
      invitePeople: '邀請人員',
      enterName: '請輸入暱稱搜索...',
      project: '所在項目',
      state: '狀態',
      all: '全部',
      userId: '用戶Id',
      username: '姓名',
      email: '郵箱',
      action: '操作',
      enabled: '已激活',
      disabled: '未激活'
    }
  },
  project: {
    projectSettings: '項目設置',
    searchProject: '搜索項目',
    menu: {
      projectInfo: '項目信息',
      projectMembers: '項目人員',
      projectRole: '項目角色',
      projectAppsManagement: '項目應用管理'
    },
    projectList: {
      members: '{count}名成員',
      started: '已開始{days}天',
      lastUpdated: '最近更新{time}'
    },
    favAppSuccess: '收藏應用成功',
    deleteAppSuccess: '取消收藏應用成功'
  },
  accountProfiles: {
    userInfo: '個人信息',
    name: '姓名',
    passSafe: '安全與登錄',
    pass: '密碼',
    edit: '編輯',
    sure: '確定',
    changeName: '修改姓名',
    enterName: '請輸入姓名',
    editName: '修改名字成功',
    save: '保存',
    editPass: '修改密碼',
    oldPass: '舊密碼',
    newPassWithRule: "新密碼（8-20位密碼，包含字母、數字及符號, 符號包括({'@'}$!%*#?&.)）",
    newPass: '新密碼',
    enterNewPassAgain: '再次輸入新密碼',
    enterOldPass: '請輸入舊密碼',
    enterNewPass: '請輸入新密碼',
    passRule: "請輸入至少8位（最多20位），同時包含字母、數字和符號, 符號包括({'@'}$!%*#?&.)",
    passDifferent: '兩次密碼輸入不一致',
    // 綁定手機
    bindPhone: '綁定手機號',
    phone: '手機號',
    unbind: '未綁定',
    bind: '綁定',
    phoneTips: '請輸入手機號',
    bindPhoneTips: '請輸入手機號',
    verifyCode: '驗證碼',
    sendVerifyCode: '發送驗證碼',
    bindPhoneSuccess: '綁定手機成功',
    // 綁定郵箱
    email: '郵箱',
    emailTips: '請輸入郵箱',
    bindEmailTips: '請輸入郵箱',
    bindEmailSuccess: '綁定郵箱成功',
    emailErrorTips: '請輸入有效的電子郵件地址'
  },
  login: {
    login: '登錄',
    userInputTips: '請輸入手機號或郵箱',
    pswInputTips: '請輸入密碼',
    loginTitle: '登錄 JARVIS鷹眼',
    jllTitle: '歡迎來到築視',
    forgetPsw: '忘記密碼',
    userNullError: '請輸入手機號或郵箱',
    pswNullError: '請輸入密碼'
  },
  forgetPassword: {
    formTitle: '忘記密碼',
    phone: '手機號',
    email: '郵箱',
    verificationCode: '驗證碼',
    getCode: '獲取驗證碼',
    enterPassword: '輸入新密碼',
    confirmPassword: '確認密碼',
    resetPassword: '重置密碼',
    phoneErrorTips: '請輸入11位手機號碼',
    getVerifyCodeSuccessTips: '獲取驗證碼成功',
    confirmPasswordErrorTips: '密碼不一致',
    resetPasswordSuccessTips: '重置密碼成功',
    backToLogin: '返回登錄頁',
    emailTips: '輸入您的郵箱',
    phoneTips: '輸入您的手機號',
    verificationTips: '輸入驗證碼',
    passwordTips: '輸入密碼',
    second: '秒'
  },
  signIn: {
    formTitle: '註冊個人信息',
    email: '郵箱',
    name: '姓名',
    enterPassword: '輸入新密碼',
    confirmPassword: '再次輸入新密碼',
    signIn: '提交',
    backToLogin: '已有賬號？去登陸',
    emailTips: '輸入您的郵箱',
    nameTips: '輸入姓名',
    passwordTips: '輸入密碼',
    confirmPasswordTips: '確認密碼',
    emailNullError: '請輸入您的郵箱',
    userNullError: '請輸入姓名',
    passwordNullError: '輸入新密碼',
    checkPassNullError: '請再次輸入新密碼',
    checkPassDifferentError: '與新密碼不一致',
    emailRuleError: '請輸入正確的郵箱格式',
    passwordRuleError: '密碼必須包含大小寫字母、數字且至少8位',
    withPhone: '手機註冊',
    phone: '手機號',
    phoneNullError: '請輸入手機號',
    verificationCode: '驗證碼',
    verificationTips: '請輸入驗證碼',
    withEmail: '郵箱註冊',
    phoneRuleError: '手機號格式不正確',
    codeRoleError: '驗證碼應爲6位數字',
    sendSuccess: '發送驗證碼成功',
    signInSuccess: '註冊成功',
    appQR: '安卓下載二維碼',
    iosQR: 'IOS下載二維碼',
    downloadTip: '掃二維碼下載APP登錄，解鎖更多功能'
  },
  invite: {
    inviteTitle: '邀請加入項目',
    searchTitle: '搜索企業成員，或輸入手機號、郵箱直接邀請',
    inviteTip: '過鏈接邀請更多的人加入',
    copyLink: '複製鏈接',
    effectiveDate: '邀請鏈接有效期至',
    copySuccessed: '複製成功',
    selectOrg: '選擇企業 ',
    sure: '確定 ',
    userTitle: '項目邀請 ',
    userContent: '邀請您加入 ',
    userConfirm: '加入項目'
  },
  apps: {
    mainPage: '首頁',
    projectMember: '項目成員',
    invite: '邀請',
    allMember: '全部成員',
    addMember: '添加成員',
    memberSetting: '成員設置',
    removeMember: '移除成員'
  },
  workbench: {
    tasks: '待辦任務',
    application: '我發起的',
    cc: '抄送我的',
    noFavoriteApps: '無收藏應用',
    toProjectTips: '進入項目後鼠標移動至頂部應用名稱點擊星號圖標即可收藏應用，再次點擊取消收藏，通過{projectList}進入項目',
    projectList: '項目列表',
    haveDone: '我已辦的',
    iInitiated: '我發起的',
    iApproved: '我審批的',
    allTasks: '全部待辦',
    expired: '已過期',
    expiring: '即將到期',
    nudge: '催辦',
    processingWorkflow: '及時處理任務，能夠極大的提高您的工作效率',
    favoriteApps: '收藏的應用',
    unread: '待審核',
    underReview: '未讀',
    workCard: '工作卡片',
    iSubmitted: '我提交的',
    table: {
      title: '標題',
      flowStatus: '流程狀態',
      updateTime: '更新時間',
      initiator: '發起人'
    }
  },
  moment: {
    publishMoment: '發佈至鷹眼動態',
    publish: '發佈',
    cancel: '取消',
    selectedProject: '選擇發佈項目',
    description: '描述',
    enter: '輸入',
    select: '選擇',
    floor: '項目/區域/樓層',
    type: '工作類型',
    principals: '負責人',
    other: '其他',
    pleaseText: '請',
    publishSuccess: '發佈成功',
    publishComment: '發佈評論...',
    send: '發送',
    comment: '評論',
    replyComment: '回覆評論',
    newReply: '新增回覆...',
    reply: '回覆',
    photoDetail: '圖片詳情',
    typeCount: '類型統計',
    userCount: '用戶統計',
    tag: '標籤',
    back: '返回',
    allProject: '全部項目',
    mine: '我的',
    commented: '評論了',
    replied: '回覆了',
    liked: '點讚了',
    moment: '動態',
    detail: '詳情',
    loadMore: '加載更多',
    message: '消息',
    all: '全部',
    like: '點贊',
    eagleEye: '鷹眼動態',
    ernterContent: '請輸入內容'
  },
  appTitle: {
    // eagleMoment: '鷹眼動態',
    // jarvisEagleApp: '鷹眼介紹 ',
    // realTwinApp: '超真實孿生 ',
    // processApp: '過程記錄 ',
    // remoteMonitoringApp: '遠程監控 ',
    // assetsManager: '資產管理'
  }

}
