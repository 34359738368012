import { createRouter, createWebHistory } from 'vue-router'
import clientChildren from './module/client'
import enterpriseChildren from './module/admin/enterprise'
import projectChildren from './module/admin/project'
import appsChildren from './module/apps'
import userChildren from './module/user'
import store from '../store'
import { USERS, CLIENT, COMMON } from '@/store/type'
import { client } from './path'
import api from '@/services/api'
import config from '@/config'
import { prefetchApps } from 'qiankun'
import { showModal } from '@/components/invite/InviteModal'

import AppIndex from '../views/client/apps/Index.vue'
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      noAuthentication: true
    }
  },
  {
    path: '/invite/:inviteId',
    name: 'Invite',
    component: () => import('../views/Invite.vue'),
    meta: {
      noAuthentication: true
    },
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/client/Index.vue'),
    redirect: clientChildren[0].redirect ? clientChildren[0].redirect : '/' + clientChildren[0].path,
    children: clientChildren
  },
  {
    path: '/:orgId/user',
    name: 'User',
    component: () => import('../views/user/Index.vue'),
    redirect: userChildren[0].redirect ? userChildren[0].redirect : '/user/' + userChildren[0].path,
    children: userChildren
  },
  {
    path: '/:orgId/:projectId/admin',
    component: () => import('../views/admin/Index.vue'),
    redirect: projectChildren[0].redirect ? projectChildren[0].redirect : '/admin/' + projectChildren[0].path,
    children: projectChildren
  },
  {
    path: '/:orgId/admin',
    component: () => import('../views/admin/Index.vue'),
    redirect: enterpriseChildren[0].redirect ? enterpriseChildren[0].redirect : '/admin/' + enterpriseChildren[0].path,
    children: enterpriseChildren
  },
  {
    path: '/:orgId/:projectId',
    name: 'Project',
    component: AppIndex,
    // redirect: appsChildren[0].redirect ? appsChildren[0].redirect : '/:id/' + appsChildren[0].path,
    children: appsChildren
  },
  {
    path: '/' + client.SHARE_DETAIL + '/:shareId/',
    name: client.SHARE_DETAIL,
    component: () => import('@/views/client/Share.vue'),
    props: true,
    meta: {
      noAuthentication: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 获取企业应用列表
const getAppsList = async (orgId) => {
  const res = await api.app.getEnterpriseApps({ orgId })
  if (res.code === 200) {
    const obj = {}
    const list = res.data.filter(element => {
      const entryFilter = element.configs.filter(i => i.entitySign === 'entry')
      let bool = true
      if (obj[element.appGroupSign]) bool = false
      obj[element.appGroupSign] = true
      return entryFilter && entryFilter.length && entryFilter[0] && entryFilter[0].value && bool
    })
    const preAppList = []
    for (let index = 0; index < list.length; index++) {
      const element = list[index]
      if (element.configs && element.configs.length) {
        const entryFilter = element.configs.filter(i => i.entitySign === 'entry')
        if (entryFilter && entryFilter.length && entryFilter[0] && entryFilter[0].value) {
          preAppList.push({ name: element.appGroupSign, entry: config.DEVELOPMENT ? config.DEBUGGER_APP_URL : (config.BASE_APP_PATH + entryFilter[0].value) })
        }
      }
    }
    prefetchApps(preAppList)
  }
}

// 需要保留的query列表
const keepQuery = ['h']

router.beforeEach((to, from, next) => {
  // debugger
  // if (!history.state.current) {
  //   Object.assign(history.state, { current: from.fullPath })
  // }
  if (to.query && to.query.h) {
    const list = to.query.h.split(',')
    console.log(list)
    list.map(i => {
      switch (i) {
        case '99':
          store.dispatch(COMMON.SET_HEADER_HIDDEN, true)
          break
        case '100':
          store.dispatch(COMMON.SET_LOGO_HIDDEN, true)
          break

        default:
          break
      }
    })
  }
  if (to.query && to.query.token) {
    store.dispatch('setToken', to.query.token)
    // debugger
    delete to.query.token
    next({
      name: to.name,
      query: to.query,
      params: to.params
    })
  }

  if (to.query && to.query.cdeToken) {
    store.dispatch('setCDEToken', to.query.cdeToken)
    delete to.query.cdeToken
    next({
      name: to.name,
      query: to.query,
      params: to.params
    })
  }

  // 检查需要保留的query
  const checkQuery = (query = from?.query) => {
    if (query) {
      const tquery = {}
      let hasProp = false
      keepQuery.map(i => {
        if (query[i]) {
          hasProp = true
          tquery[i] = query[i]
        }
      })
      if (hasProp) {
        return tquery
      } else {
        return false
      }
    }
    return false
  }

  if (checkQuery(to.query) || !checkQuery(from.query)) {
  } else {
    next({
      name: to.name,
      query: checkQuery() || {},
      params: to.params
    })
  }

  if (to.name === 'Login' && store.state.token) {
    next({
      path: '/',
      query: checkQuery(to.query)
    })
  } else if ((!to.meta || !to.meta.noAuthentication) && !store.state.token) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath }
    })
  } else {
    if (to.meta && to.meta.noAuthentication) {
      next()
    // } else if (to.meta && to.meta.noOrgId) {
    //   // 获取并设置用户信息
    //   store.dispatch('user/' + USERS.SET_USER_INFO)
    //   next()
    } else if (to.params.orgId && to.params.orgId !== from.params.orgId) {
      // 获取企业详情
      console.log(to.params.orgId)
      store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_INFO, { orgId: to.params.orgId })
      // 获取并设置用户信息
      store.dispatch('user/' + USERS.SET_USER_INFO)
      // 预加载子应用
      getAppsList(to.params.orgId)
      next()
    } else if (!to.params.orgId) {
      // 获取并设置用户信息

      console.log('router', router)
      store.dispatch('user/' + USERS.SET_USER_INFO, {
        callback: (orgId) => {
          next({
            name: client.PROJECT_LIST,
            params: {
              ...to.params,
              orgId
            },
            query: to.query
          })
        },
        noOrgIdCallback: () => { showModal(router) }
      })
    } else {
      next()
    }
  }
})

export default router
